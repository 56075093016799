import React, { useMemo } from 'react'
import { retryExchange } from '@urql/exchange-retry'
import { createClient, dedupExchange, errorExchange, fetchExchange, Provider } from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import { refocusExchange } from '@urql/exchange-refocus'
import type { Cache, UpdateResolver } from '@urql/exchange-graphcache'
import { cacheExchange } from '@urql/exchange-graphcache'
import type { IntrospectionQuery } from 'graphql'
import type { Mutation, Query, EmailEnabled } from 'packlets/generated'
import { introspection } from 'packlets/generated'
import { AuthService } from 'services'
import { useAccessToken } from 'providers'

export const dropAllQueriesWithField = (field: keyof Query, cache: Cache) => {
  const queries = cache.inspectFields('Query').filter((x) => x.fieldName === field)
  queries.forEach(({ fieldName, arguments: variables }) => {
    cache.invalidate('Query', fieldName, variables ?? undefined)
  })
}

export const UrqlProvider: React.FC = ({ children }) => {
  const token = useAccessToken()
  const client = useMemo(() => {
    const options: Parameters<typeof retryExchange>[0] = {
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err) => !!err.networkError,
    }

    return createClient({
      url: '/graphql',
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        refocusExchange(),
        cacheExchange({
          schema: (introspection as unknown) as IntrospectionQuery,
          keys: {
            EmailEnabled: (data) => (data as EmailEnabled).email,
          },
          updates: {
            Mutation: {
              companyLinkToSubscriptionPlan: (result, __, cache) => {
                dropAllQueriesWithField('company', cache)
              },
              enabledEmailAdd: (result, __, cache) => {
                dropAllQueriesWithField('enabledEmails', cache)
              },
              enabledEmailDelete: (result: any, __, cache) => {
                if (result && result?.enabledEmailDelete?.email) {
                  cache.invalidate({
                    __typename: 'EmailEnabled',
                    email: result.enabledEmailDelete.email,
                  })
                }
              },
            } as {
              [K in keyof Omit<Mutation, '__typename' | 'stub'>]: UpdateResolver
            },
          },
        }),
        retryExchange(options),
        errorExchange({
          onError: (error) => {
            if (error.response?.status === 401) {
              AuthService.logout()
            }
          },
        }),
        fetchExchange,
      ],
      requestPolicy: 'cache-and-network',
      fetchOptions: () => {
        return {
          headers: { authorization: token ? `Bearer ${token}` : '' },
        }
      },
    })
  }, [token])

  return <Provider value={client}>{children}</Provider>
}
