import React from 'react'
import { Button, Center, Divider, Grid, Stack, Image, Box } from '@chakra-ui/react'
import { FaGoogle } from 'react-icons/fa'
import { Card, FixedThemeToggleButton } from '@liveflow-io/component-common'
import { useIsLoggedIn } from 'providers'
import { Navigate } from 'react-router-dom'
import liveflowLogo from 'static/liveflow-logo-big.png'
import { Routes } from 'packlets/constants'
import { AuthService } from 'services/AuthService'

export const SignIn = () => {
  const isLoggedIn = useIsLoggedIn()
  return (
    <Grid w="100%" minHeight="inherit" alignItems="center" justifyItems="center">
      {isLoggedIn && <Navigate to={Routes.SEARCH} />}
      <FixedThemeToggleButton />
      <Card w={['100%', '30em']}>
        <Stack spacing={4}>
          <Center my={4}>
            <Image src={liveflowLogo} width={150} alt="Liveflow logo" />
          </Center>
          <Divider />
        </Stack>
        <Box mt={8} justifyContent="space-between">
          <Button
            type="button"
            colorScheme="red"
            leftIcon={<FaGoogle />}
            onClick={() => {
              AuthService.loginWithGSuite()
            }}
            isFullWidth
          >
            Sign-in using GSuite
          </Button>
        </Box>
      </Card>
    </Grid>
  )
}
