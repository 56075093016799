export { degraphqlData } from './graphql'
export type { OmitRecursively, Unpacked } from './type-utils'
export { formatMoney, formatNumber, renderMoney, renderMoneyOrNA } from './formatters'
export type { Money } from './formatters'
export { removeEmptyStringFields } from './form'
export { getEnvByHostname, isProduction } from './env'
export { default as dayjs, utcDayJs } from './date'
export { pruneEmpty, isNullish, isEmptyOrNullish } from './utils'

export function impossibleState(..._: never[]): never {
  throw new Error('should never be reached')
}
