import React, { useCallback, useState } from 'react'
import { Navigate, useParams } from 'react-router'
import { Routes } from 'packlets/constants'
import type { Company_CompanyQuery } from './documents.generated'
import {
  Company_CompanyDocument,
  Company_AddChangeSubscriptionPlanDocument,
  Company_SubscriptionPlansDocument,
} from './documents.generated'
import {
  useEnchancedMutation,
  useEnchancedQuery,
  useToast,
} from '@liveflow-io/hooks-common'
import {
  Card,
  GenericEmpty,
  GenericError,
  GenericSpinner,
} from '@liveflow-io/component-common'
import {
  formatMoney,
  impossibleState,
  pruneEmpty,
  renderMoney,
  utcDayJs,
} from '@liveflow-io/utils-common'
import {
  Box,
  Button,
  Code,
  Collapse,
  Heading,
  Modal,
  SlideFade,
  Stack,
  Table,
  Tbody,
  Td,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useQuery } from 'urql'

export const CompanyPage = () => {
  const { companyId } = useParams()
  const [companyState] = useEnchancedQuery({
    query: Company_CompanyDocument,
    pause: !companyId,
    variables: {
      id: companyId,
    },
  })
  if (!companyId) {
    return <Navigate to={Routes.SEARCH} />
  }
  switch (companyState.state) {
    case 'idle':
      return <GenericEmpty />
    case 'fetching':
      return <GenericSpinner />
    case 'error':
      return <GenericError />
    case 'partial':
    case 'stale':
    case 'partial-stale':
    case 'done': {
      if (!companyState.data.company) {
        return <GenericEmpty />
      }
      return <Company data={companyState.data.company} />
    }
    default:
      return impossibleState(companyState)
  }
}

type Companies = NonNullable<Company_CompanyQuery['company']>

type Transactions = Companies['transactions']
type Users = Companies['users']
type Budgets = Companies['budgets']
type BankAccounts = Companies['bankAccounts']
type Integrations = Companies['integrations']

const Company = ({ data }: { data: NonNullable<Company_CompanyQuery['company']> }) => {
  const price = data.subscriptionPlan?.subscriptionPlan.price
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [documentsQuery] = useQuery({
    query: Company_SubscriptionPlansDocument,
  })
  const [, addOrChangePlan] = useEnchancedMutation(
    Company_AddChangeSubscriptionPlanDocument,
  )

  const onAddOrChangePlan = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const subscriptionPlanId = e.currentTarget.dataset.subscriptionPlanId
      const companyId = data.id
      onClose()
      if (subscriptionPlanId && companyId)
        addOrChangePlan({
          subscriptionPlanId,
          companyId,
        })
          .then((res) => {
            switch (res.state) {
              case 'partial':
              case 'error':
                toast({
                  status: 'error',
                  description: res.error.message,
                })
                break
              case 'done':
                toast({
                  title: 'Success!',
                  description: 'Plan has been successfully added to company!',
                })
                break
              default:
                impossibleState(res)
            }
            return res
          })
          .catch(console.error)
    },
    [addOrChangePlan, data.id, onClose, toast],
  )

  return (
    <SlideFade in>
      <Stack spacing={2}>
        <Heading>{data.name}</Heading>
        <Stack spacing={16}>
          <Stack direction="row" spacing={16}>
            <Stack>
              <Box>
                <Text>ID: {data.id}</Text>
                <Text>Name: {data.name}</Text>
                <Text>E-mail: {data.email}</Text>
                <Text>Created date: {utcDayJs(data.createdDate).formatDateTime()}</Text>
                <Text>Updated date: {utcDayJs(data.updatedDate).formatDateTime()}</Text>
                <Text>Postcode: {data.postCode}</Text>
                <Text>State: {data.state}</Text>
              </Box>
              <Box>
                <Heading size="md">Subscription</Heading>
                <Text>Price: {price ? renderMoney(price) : 'N/A'}</Text>
                <Button onClick={onOpen}>Add/Change subscription</Button>
              </Box>
            </Stack>
            <Box flex="1 1">
              <Heading size="md">Users</Heading>
              <UsersTable data={data.users} />
            </Box>
            <Box flex="1 1">
              <Heading size="md">Budgets</Heading>
              <BudgetsTable data={data.budgets} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={16}>
            <Box flex="1 1">
              <Heading size="md">Integrations</Heading>
              <IntegrationsTable data={data.integrations} />
            </Box>
            <Box flex="1 1">
              <Heading size="md">BankAccounts</Heading>
              <BankAccountsTable data={data.bankAccounts} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={16}>
            <Box flex="1 1">
              <Heading size="md">Transactions</Heading>
              <TransactionsTable data={data.transactions} />
            </Box>
          </Stack>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Available plans</ModalHeader>
            <ModalCloseButton />
            <ModalBody as={Stack} direction="row" flexWrap="wrap" spacing={6}>
              {documentsQuery.data?.subscriptionPlans
                .filter((plan) => plan.id !== data.subscriptionPlan?.subscriptionPlan.id)
                .map((plan) => {
                  return (
                    <Card
                      key={plan.id}
                      data-subscription-plan-id={plan.id}
                      onClick={onAddOrChangePlan}
                      textAlign="center"
                      flex="1 1"
                      sx={{
                        userSelect: 'none',
                        cursor: 'pointer',
                        transition: 'transform 0.2s',
                      }}
                      _hover={{ transform: 'scale(1.03)' }}
                      _active={{ transform: 'scale(1.00)' }}
                    >
                      <Text fontSize="2xl">{renderMoney(plan.price)}</Text>
                    </Card>
                  )
                })}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </SlideFade>
  )
}

const JsonReveal = ({ object }: { object: object }) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Stack>
      <Button size="sm" onClick={onToggle}>
        Show
      </Button>
      <Collapse in={isOpen}>
        <pre>
          <Code>{JSON.stringify(pruneEmpty(object), null, 2)}</Code>
        </pre>
      </Collapse>
    </Stack>
  )
}

function paginate<T>(array: T[], pageSize: number, pageNumber: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const TransactionsTable = ({ data }: { data: Transactions }) => {
  const [page, setPage] = useState<number>(1)
  const incomeBg = useColorModeValue('green.100', 'green.900')
  const expenseBg = useColorModeValue('red.100', 'red.900')
  if (data.length === 0) {
    return <GenericEmpty />
  }
  const pageSize = 25
  const pages = Math.ceil(data.length / pageSize)
  const pageSet = paginate(data, pageSize, page)
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Td colSpan={5}>
            <Stack justifyContent="center" alignItems="center">
              <Text>Page</Text>
              <Stack direction="row" justifyContent="center" flexWrap="wrap">
                {Array(pages)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <Button
                        size="sm"
                        variant="ghost"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => setPage(index + 1)}
                        disabled={index + 1 === page}
                      >
                        {index + 1}
                      </Button>
                    )
                  })}
              </Stack>
            </Stack>
          </Td>
        </Tr>
        <Tr>
          <Th>Amount</Th>
          <Th>Origin date</Th>
          <Th>Source</Th>
          <Th>Body</Th>
        </Tr>
      </Thead>
      <Tbody>
        {pageSet.map((tx) => (
          <Tr key={tx.id} bg={tx.type === 'INCOME' ? incomeBg : expenseBg}>
            <Td>{formatMoney(tx.amount)}</Td>
            <Td>{utcDayJs(tx.originDate).formatYyyyMmDd()}</Td>
            <Td>{tx.source}</Td>
            <Td>
              <JsonReveal object={tx.body} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const IntegrationsTable = ({ data }: { data: Integrations }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>id</Th>
          <Th>source</Th>
          <Th>created date</Th>
          <Th>metadata</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((integration) => (
          <Tr key={integration.id}>
            <Td>{integration.id}</Td>
            <Td>{integration.source}</Td>
            <Td>{utcDayJs(integration.createdDate).formatDateTime()}</Td>
            <Td>
              <JsonReveal object={integration.metadata} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const UsersTable = ({ data }: { data: Users }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>id</Th>
          <Th>first Name</Th>
          <Th>idp User Id</Th>
          <Th>created Date</Th>
          <Th>email</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((user) => (
          <Tr key={user.id}>
            <Td>{user.id}</Td>
            <Td>{user.firstName}</Td>
            <Td>{user.idpUserId}</Td>
            <Td>{utcDayJs(user.createdDate).formatYyyyMmDd()}</Td>
            <Td>{user.email}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const BankAccountsTable = ({ data }: { data: BankAccounts }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>id</Th>
          <Th>balance</Th>
          <Th>created Date</Th>
          <Th>integration source</Th>
          <Th>accounts</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((accounts) => (
          <Tr key={accounts.id}>
            <Td>{accounts.id}</Td>
            <Td>{formatMoney(accounts?.balance ?? 0)}</Td>
            <Td>{utcDayJs(accounts.createdDate).formatYyyyMmDd()}</Td>
            <Td>{accounts.integration.source}</Td>
            <Td>
              <JsonReveal object={accounts.accounts} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const BudgetsTable = ({ data }: { data: Budgets }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>start Date</Th>
          <Th>updated Date</Th>
          <Th>expected Cash In</Th>
          <Th>expected Cash Out</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((budget) => (
          <Tr key={budget.id}>
            <Td>{utcDayJs(budget.startDate).formatDateTime()}</Td>
            <Td>{utcDayJs(budget.endDate).formatDateTime()}</Td>
            <Td>{formatMoney(budget?.expectedCashIn ?? 0)}</Td>
            <Td>{formatMoney(budget.expectedCashOut ?? 0)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
