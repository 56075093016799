import React from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box, useColorModeValue } from '@chakra-ui/react'

export const Card = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ title, children, ...rest }, ref) => (
    <Box
      py="6"
      px="8"
      rounded="lg"
      bg={useColorModeValue('white', 'gray.700')}
      shadow="base"
      overflow="hidden"
      ref={ref}
      {...rest}
    >
      {children}
    </Box>
  ),
)
