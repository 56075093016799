import React from 'react'
import { Heading, SlideFade, Stack } from '@chakra-ui/react'
import { Companies_CompanyDocument } from './document.generated'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import { impossibleState } from '@liveflow-io/utils-common'
import { GenericError, GenericSpinner } from '@liveflow-io/component-common'
import { CompaniesTable } from 'components/CompaniesTable'

export const Companies = () => {
  return (
    <SlideFade in>
      <Stack spacing={4}>
        <Heading>Companies</Heading>
        <CompaniesResult />
      </Stack>
    </SlideFade>
  )
}

const CompaniesResult = () => {
  const [companiesState] = useEnchancedQuery({
    query: Companies_CompanyDocument,
    variables: { input: {} },
  })

  switch (companiesState.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'error':
      return <GenericError />
    case 'done':
    case 'partial':
    case 'partial-stale':
    case 'stale': {
      return <CompaniesTable data={companiesState.data.companies} />
    }
    default:
      return impossibleState(companiesState)
  }
}
