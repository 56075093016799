import React, { useState } from 'react'
import {
  Button,
  Heading,
  Input,
  SlideFade,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  EmailsWhitelist_EnabledEmailDocument,
  EmailsWhitelist_EnabledEmail_AddDocument,
  EmailsWhitelist_EnabledEmail_DeleteDocument,
} from './document.generated'
import { useEnchancedQuery, useToast } from '@liveflow-io/hooks-common'
import { impossibleState } from '@liveflow-io/utils-common'
import { GenericError, GenericSpinner } from '@liveflow-io/component-common'
import { string } from 'zod'
import { useMutation } from 'urql'

export const EmailsWhitelist = () => {
  return (
    <SlideFade in>
      <Stack spacing={4}>
        <Heading>Email Whitelist</Heading>
        <EmailsWhiltelistTable />
      </Stack>
    </SlideFade>
  )
}

const emailSchema = string().email()

const EmailsWhiltelistTable = () => {
  const toast = useToast()
  const [emailsWhitelist] = useEnchancedQuery({
    query: EmailsWhitelist_EnabledEmailDocument,
  })
  const [, addEmail] = useMutation(EmailsWhitelist_EnabledEmail_AddDocument)
  const [, deleteEmail] = useMutation(EmailsWhitelist_EnabledEmail_DeleteDocument)
  const hoverBg = useColorModeValue('gray.50', 'gray.700')
  const [email, setEmail] = useState<string>('')

  const onAddEmail: React.FormEventHandler = (e) => {
    e.preventDefault()
    if (emailSchema.safeParse(email).success) {
      addEmail({ email })
        .then((it) => {
          if (it.error) {
            toast({
              title: 'Something went wrong',
              description: JSON.stringify(it.data, null, 2),
              status: 'error',
            })
          } else {
            toast({
              title: 'Success',
              description: 'E-mail has been added to the whitelist!',
            })
            setEmail('')
          }
          return it
        })
        .catch(console.error)
    } else {
      toast({
        title: "It's not an e-mail!",
        description: 'Please make sure you passed e-mail',
        status: 'error',
      })
    }
  }

  const onDeleteEmail = (anEmail: string) => {
    deleteEmail({ email: anEmail })
      .then((it) => {
        if (it.error) {
          toast({
            title: 'Something went wrong',
            description: JSON.stringify(it.data, null, 2),
            variant: 'error',
          })
        }
        return it
      })
      .catch(console.error)
  }

  switch (emailsWhitelist.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'error':
    case 'partial':
    case 'partial-stale':
      return <GenericError />
    case 'done':
    case 'stale': {
      return (
        <form onSubmit={onAddEmail}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th />
              </Tr>
              <Tr>
                <Th>
                  <Input
                    value={email}
                    placeholder="john@doe.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Th>
                <Th>
                  <Button colorScheme="blue" type="submit">
                    Add email
                  </Button>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {emailsWhitelist.data.enabledEmails.map((enabledEmail) => {
                return (
                  <Tr
                    key={enabledEmail.email}
                    _hover={{
                      transition: 'all 0.2s',
                      bg: hoverBg,
                    }}
                  >
                    <Td>{enabledEmail.email}</Td>
                    <Td>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => onDeleteEmail(enabledEmail.email)}
                      >
                        Delete
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </form>
      )
    }
    default:
      return impossibleState(emailsWhitelist)
  }
}
