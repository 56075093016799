import { Button, Flex, Box, Stack, Link, useColorModeValue } from '@chakra-ui/react'

import { Navigate, Outlet, useLocation, Link as RouterLink } from 'react-router-dom'
import { Routes } from 'packlets/constants'
import React from 'react'
import { AuthService } from '../../services'
import { ThemeToggleButton } from '@liveflow-io/component-common'

export const SecuredApp = () => {
  const location = useLocation()
  const navBgColor = useColorModeValue('blue.500', 'blue.200')
  const navFontColor = useColorModeValue('white', 'black')
  if (location.pathname === '/') {
    return <Navigate to={Routes.SEARCH} />
  }
  return (
    <Flex flexDirection="column" height="100%">
      <Stack
        direction="row"
        alignItems="center"
        p={4}
        bg={navBgColor}
        color={navFontColor}
        justifyContent="space-between"
        spacing={4}
      >
        <Stack spacing={4} direction="row">
          <Link as={RouterLink} to={Routes.SEARCH} fontSize="lg">
            Search
          </Link>
          <Link as={RouterLink} to={Routes.COMPANIES} fontSize="lg">
            Companies
          </Link>
          <Link as={RouterLink} to={Routes.EMAILS_WHITELIST} fontSize="lg">
            Whitelist emails
          </Link>
        </Stack>
        <Stack spacing={4} direction="row">
          <ThemeToggleButton colorScheme="blue" />
          <Button colorScheme="blue" onClick={AuthService.logout}>
            Logout
          </Button>
        </Stack>
      </Stack>
      <Box pl={4} pr={4} mt={4}>
        <Outlet />
      </Box>
    </Flex>
  )
}
