import {
  isUndefined,
  isNull,
  isNaN,
  isString,
  isObject,
  isEmpty,
  isArray,
  pull,
  cloneDeep,
  forOwn,
} from 'lodash'

/**
 * Remove not only null values but also undefined, NaN, empty String, empty array and empty object values, recursively, by inspecting nested objects and also nested arrays.
 * @param obj
 */
export function pruneEmpty(obj: any) {
  return (function prune(current) {
    forOwn(current, (value, key) => {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isString(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key]
      }
    })
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined)

    return current
  })(cloneDeep(obj)) // Do not modify the original object, create a clone instead
}

export const isNullish = <T>(value: T | null | undefined): value is null | undefined => {
  return isNull(value) || isUndefined(value)
}

export const isEmptyOrNullish = <T>(
  value: T | null | undefined,
): value is null | undefined => {
  return isNullish(value) || isEmpty(value)
}
