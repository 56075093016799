import { useToast as useChakraToast } from '@chakra-ui/react'
import { useMemo } from 'react'
import type { UseToastOptions } from '@chakra-ui/react'

export const useToast = (options?: UseToastOptions) => {
  const defaultOptions = useMemo(() => {
    return {
      position: 'top-right' as UseToastOptions['position'],
      status: 'success' as UseToastOptions['status'],
      isClosable: true,
      ...(options ?? {}),
    }
  }, [options])
  return useChakraToast(defaultOptions)
}
