import type {
  CombinedError,
  OperationResult,
  TypedDocumentNode,
  UseMutationResponse,
  UseQueryArgs,
  UseQueryResponse,
} from 'urql'
import { useMutation, useQuery } from 'urql'
import { useCallback, useMemo } from 'react'
import type { DocumentNode } from 'graphql'

export type UrqlQueryResult<Data = any, Variables = object> = UseQueryResponse<
  Data,
  Variables
>[0]
export type UrqlRefetchFn<T> = UseQueryResponse<T>[1]

export type UrqlMutationResult<Data = any, Variables = object> = UseMutationResponse<
  Data,
  Variables
>[0]
export type UrqlMutateFn<Data = any, Variables = object> = UseMutationResponse<
  Data,
  Variables
>[1]

export type EnhancedMutate<Data = any, Variables = object> = (
  ...args: Parameters<UrqlMutateFn<Data, Variables>>
) => Promise<UrqlOperationResultStates<Data>>

function useGetUrqlMemoizedStates<Data = any, Variables = object>(
  state: UrqlQueryResult<Data> | UrqlMutationResult<Data, Variables>,
) {
  const memoizedIdleState = useMemo<IdleState>(() => ({ state: 'idle' }), [])

  const memoizedPartialState = useMemo<PartialState<Data>>(
    () => ({
      state: 'partial',
      error: state.error!,
      data: state.data!,
      stale: false,
    }),
    [state.data, state.error],
  )

  const memoizedPartialStaleState = useMemo<PartialStaleState<Data>>(
    () => ({
      state: 'partial-stale',
      error: state.error!,
      data: state.data!,
      stale: true,
    }),
    [state.data, state.error],
  )

  const memoizedErrorState = useMemo<ErrorState>(
    () => ({ error: state.error!, state: 'error' }),
    [state.error],
  )

  const memoizedFetchingState = useMemo<FetchingState>(() => ({ state: 'fetching' }), [])

  const memoizeStale = useMemo<StaleState<Data>>(
    () => ({ data: state.data!, stale: true, state: 'stale' }),
    [state.data],
  )

  const memoizedDoneState = useMemo<DoneState<Data>>(
    () => ({
      data: state.data!,
      stale: false,
      state: 'done',
    }),
    [state.data],
  )
  return {
    memoizedIdleState,
    memoizedPartialStaleState,
    memoizedPartialState,
    memoizedErrorState,
    memoizedFetchingState,
    memoizeStale,
    memoizedDoneState,
  }
}

export const useStatefulQuery = <Data = any, Variables = object>([
  state,
]: UseQueryResponse<Data, Variables>): UrqlStates<Data> => {
  const {
    memoizedIdleState,
    memoizedPartialStaleState,
    memoizedPartialState,
    memoizeStale,
    memoizedDoneState,
    memoizedErrorState,
    memoizedFetchingState,
  } = useGetUrqlMemoizedStates(state)

  if (isInitialState(state)) {
    return memoizedIdleState
  }

  if (state.fetching) {
    return memoizedFetchingState
  }

  if (state.error && state.data && state.stale) {
    return memoizedPartialStaleState
  }

  if (state.error && state.data) {
    return memoizedPartialState
  }

  if (state.error) {
    return memoizedErrorState
  }

  if (state.stale && state.data) {
    return memoizeStale
  }

  if (state.data) {
    return memoizedDoneState
  }
  throw Error('This is impossible state!')
}

const isInitialState = <Data = any, Variables = object>(
  state: UrqlMutationResult<Data, Variables> | UrqlQueryResult<Data>,
) => {
  return (
    !state.fetching && !state.operation && !state.data && !state.stale && !state.error
  )
}

export const useStatefulMutation = <Data = any, Variables = object>([
  state,
  mutate,
]: UseMutationResponse<Data, Variables>): [
  UrqlStates<Data>,
  EnhancedMutate<Data, Variables>,
] => {
  const {
    memoizedIdleState,
    memoizedPartialState,
    memoizedPartialStaleState,
    memoizeStale,
    memoizedDoneState,
    memoizedErrorState,
    memoizedFetchingState,
  } = useGetUrqlMemoizedStates(state)

  type StatefulMutate = EnhancedMutate<Data, Variables>
  const mutateStateful = useCallback<StatefulMutate>(
    (...args: Parameters<UrqlMutateFn<Data, Variables>>) => {
      return (mutate as UrqlMutateFn<Data, Variables>)(...args).then((response) =>
        buildStateFromResponse(response),
      )
    },
    [mutate],
  )

  const memoizedIdleStateWithMutate = useMemo<[IdleState, StatefulMutate]>(
    () => [memoizedIdleState, mutateStateful],
    [memoizedIdleState, mutateStateful],
  )

  const memoizedPartialStateWithMutate = useMemo<[PartialState<Data>, StatefulMutate]>(
    () => [memoizedPartialState, mutateStateful],
    [memoizedPartialState, mutateStateful],
  )

  const memoizedPartialStaleStateWithMutate = useMemo<
    [PartialStaleState<Data>, StatefulMutate]
  >(() => [memoizedPartialStaleState, mutateStateful], [
    memoizedPartialStaleState,
    mutateStateful,
  ])

  const memoizeStaleWithMutate = useMemo<[StaleState<Data>, StatefulMutate]>(
    () => [memoizeStale, mutateStateful],
    [memoizeStale, mutateStateful],
  )

  const memoizedDoneStateWithMutate = useMemo<[DoneState<Data>, StatefulMutate]>(
    () => [memoizedDoneState, mutateStateful],
    [memoizedDoneState, mutateStateful],
  )

  const memoizedErrorStateWithMutate = useMemo<[ErrorState, StatefulMutate]>(
    () => [memoizedErrorState, mutateStateful],
    [memoizedErrorState, mutateStateful],
  )

  const memoizedFetchingStateWithMutate = useMemo<[FetchingState, StatefulMutate]>(
    () => [memoizedFetchingState, mutateStateful],
    [memoizedFetchingState, mutateStateful],
  )

  if (isInitialState(state)) {
    return memoizedIdleStateWithMutate
  }

  if (state.error && state.data && state.stale) {
    return memoizedPartialStaleStateWithMutate
  }

  if (state.fetching) {
    return memoizedFetchingStateWithMutate
  }

  if (state.error && state.data) {
    return memoizedPartialStateWithMutate
  }

  if (state.error) {
    return memoizedErrorStateWithMutate
  }

  if (state.stale && state.data) {
    return memoizeStaleWithMutate
  }

  if (state.data) {
    return memoizedDoneStateWithMutate
  }
  throw Error('This is impossible state!')
}

export type UrqlStates<Data> =
  | FetchingState
  | DoneState<Data>
  | ErrorState
  | IdleState
  | StaleState<Data>
  | PartialState<Data>
  | PartialStaleState<Data>

export type FetchingState = {
  state: 'fetching'
}

export type DoneState<Data> = {
  state: 'done'
  data: Data
  stale: false
}

export type StaleState<Data> = {
  state: 'stale'
  data: Data
  stale: true
}

export type IdleState = {
  state: 'idle'
}

export type ErrorState = {
  state: 'error'
  error: CombinedError
}

export type PartialState<Data> = {
  state: 'partial'
  error: CombinedError
  data: Data
  stale: false
}

export type PartialStaleState<Data> = {
  state: 'partial-stale'
  error: CombinedError
  data: Data
  stale: true
}

export type UrqlOperationResultStates<Data> =
  | DoneState<Data>
  | ErrorState
  | PartialState<Data>

const buildStateFromResponse = <Data = any>(
  response: OperationResult<Data>,
): UrqlOperationResultStates<Data> => {
  const { data, error } = response

  if (error && data) {
    return { state: 'partial', error, data, stale: false }
  }

  if (error) {
    return { state: 'error', error }
  }

  if (data) {
    return { state: 'done', data, stale: false }
  }
  throw Error('Impossible state!')
}

/**
 * Returns array of 4 elements.
 * 0 - enriched original data wrapped into state machine
 * 1 - wrapped mutate function that returns promise with response wrapped in state machine
 * 2 - original data without any wrapper
 * 3 - original mutate promise without any wrapper
 */
export const useEnchancedMutation = <Data = any, Variables = object>(
  query: DocumentNode | TypedDocumentNode<Data, Variables> | string,
): [
  UrqlStates<Data>,
  EnhancedMutate<Data, Variables>,
  UrqlMutationResult<Data, Variables>,
  UrqlMutateFn<Data, Variables>,
] => {
  const originalMutationResult = useMutation(query)
  const [originalData, originalMutate] = originalMutationResult
  const [statefulData, statefulMutate] = useStatefulMutation(originalMutationResult)
  return useMemo(() => {
    return [statefulData, statefulMutate, originalData, originalMutate]
  }, [statefulData, statefulMutate, originalData, originalMutate])
}

/**
 * Returns array of 3 elements.
 * 0 - enriched original data wrapped into state machine
 * 1 - original refetch function
 * 2 - original data without any wrapper
 */
export const useEnchancedQuery = <Data = any, Variables = object>(
  args: UseQueryArgs<Variables, Data>,
): [UrqlStates<Data>, UrqlRefetchFn<Data>, UrqlQueryResult<Data, Variables>] => {
  const originalQueryResult = useQuery(args)
  const [originalData, refetch] = originalQueryResult
  const statefulData = useStatefulQuery(originalQueryResult)
  return useMemo(() => {
    return [statefulData, refetch, originalData]
  }, [statefulData, refetch, originalData])
}
