import type { FormEventHandler } from 'react'
import React, { useCallback, useRef, useState } from 'react'
import { Button, Heading, Input, SlideFade, Stack } from '@chakra-ui/react'
import { Search_CompanyDocument } from './document.generated'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import { impossibleState } from '@liveflow-io/utils-common'
import { GenericEmpty, GenericError, GenericSpinner } from '@liveflow-io/component-common'
import { CompaniesTable } from 'components/CompaniesTable'

export const Search = () => {
  const input = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState<string>('')
  const triggerSearch = useCallback<FormEventHandler>((e) => {
    e.preventDefault()
    setSearch(input.current?.value ?? '')
  }, [])
  return (
    <SlideFade in>
      <Stack spacing={4} as="form" onSubmit={triggerSearch}>
        <Heading>Search</Heading>
        <Input
          ref={input}
          placeholder="Input email, name or whatever about the company"
        />
        <Button colorScheme="blue" type="submit">
          Search
        </Button>
        <SearchResult search={search} />
      </Stack>
    </SlideFade>
  )
}

const SearchResult = ({ search }: { search: string }) => {
  const [companiesState] = useEnchancedQuery({
    query: Search_CompanyDocument,
    pause: search === '',
    variables: {
      input: {
        search,
      },
    },
  })

  switch (companiesState.state) {
    case 'idle':
      return <GenericEmpty />
    case 'fetching':
      return <GenericSpinner />
    case 'error':
      return <GenericError />
    case 'done':
    case 'partial':
    case 'partial-stale':
    case 'stale': {
      return <CompaniesTable data={companiesState.data.companies} />
    }
    default:
      return impossibleState(companiesState)
  }
}
