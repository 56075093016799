import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

declare module 'dayjs' {
  interface Dayjs {
    formatYyyyMm(): string
    formatYyyyMmDd(): string
    formatDateTime(): string
    formatRfc3339(): string
    toGraphQLDateTime(): string
    toGraphQLDate(): string
    toGraphQLTime(): string
  }
  /**
   * Parses YYYY-MM to DayJS UTC date. Requires utc plugin. Be aware of parsing it to local date
   * @param config
   */
  export function parseYyyyMm(config: dayjs.ConfigType): dayjs.Dayjs
}

export const commonDateFunctions: dayjs.PluginFunc = (_, dayjsClass, dayJsFactory) => {
  const format = dayjsClass.prototype.format
  dayjsClass.prototype.formatYyyyMm = function formatYyyyMm() {
    return format.call(this, 'YYYY-MM')
  }

  dayjsClass.prototype.formatYyyyMmDd = function formatYyyyMmDd() {
    return format.call(this, 'YYYY-MM-DD')
  }

  dayjsClass.prototype.toGraphQLDate = dayjsClass.prototype.formatYyyyMmDd

  dayjsClass.prototype.formatDateTime = function formatDateTime() {
    return format.call(this, 'YYYY-MM-DD HH:mm:ssZ')
  }

  dayjsClass.prototype.formatRfc3339 = dayjsClass.prototype.toISOString
  dayjsClass.prototype.toGraphQLDateTime = dayjsClass.prototype.toISOString

  dayjsClass.prototype.toGraphQLTime = function toGraphQLTime() {
    return format.call(this, 'HH:mm:ssZ')
  }

  dayJsFactory.parseYyyyMm = function parseYyyyMm(date: dayjs.ConfigType) {
    return dayJsFactory.utc(date, 'YYYY-MM')
  }
}

dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(commonDateFunctions)

export const utcDayJs = (
  config?: Parameters<typeof dayjs.utc>[0],
  format?: Parameters<typeof dayjs.utc>[1],
) => {
  return dayjs.utc(config, format)
}

// eslint-disable-next-line import/no-default-export
export default dayjs
